import { useEffect } from 'react'

import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { APP_TITLE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useSelectedMarket } from '@src/domain/trade/store/market'

export const DocumentTitleUpdater = () => {
  const formatMarketAsset = useFormatMarketAsset()

  const market = useSelectedMarket()
  const marketName = market.name
  const { data: marketStats } = useMarketStats({ marketName })

  useEffect(() => {
    if (marketName && marketStats) {
      const markPrice = formatMarketAsset({
        amount: marketStats.markPrice,
        type: 'collateral',
      })

      document.title = `${markPrice} | ${marketName} | ${APP_TITLE}`
    }
  }, [marketName, marketStats, formatMarketAsset])

  return null
}
