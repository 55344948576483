import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

import { HOME_PAGE_URL } from '@x10/lib-core/config'

import { APP_TITLE } from '@src/domain/core/config/static'
import { homeRoute } from '@src/domain/core/utils/routes'
import { DocumentTitleUpdater as TradeDocumentTitleUpdater } from '@src/domain/trade/ui/components/document-title-updater'

const DefaultDocumentTitleUpdater = () => {
  useEffect(() => {
    document.title = APP_TITLE
  }, [])

  return null
}

export const DocumentTitleUpdater = () => {
  const pathname = usePathname()

  if (pathname === HOME_PAGE_URL || pathname === homeRoute({}).trade({}).$) {
    return <TradeDocumentTitleUpdater />
  }

  return <DefaultDocumentTitleUpdater />
}
