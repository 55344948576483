'use client'

import '../polyfills'
import '../../styled-system/styles.css'
import './global.css'

import { useEffect, type ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { Inter } from 'next/font/google'
import { GoogleAnalytics } from '@next/third-parties/google'

import { getLogger } from '@x10/lib-core/utils'
import { FavIcon, LayoutHtml } from '@x10/lib-ui-kit/components'
import { DEFAULT_THEME } from '@x10/lib-ui-kit/config'
import { printJobOpportunity } from '@x10/lib-ui-kit/utils'

import { getClientConfig } from '@src/domain/core/config/env'
import {
  APP_TITLE,
  CSS_STATIC_COLUMN_WIDTH_VAR,
  MAINNET_URL,
} from '@src/domain/core/config/static'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { useGlobalSettingsStore } from '@src/domain/core/store/user-settings'
import { DocumentTitleUpdater } from '@src/domain/core/ui/components/document-title-updater'
import * as analytics from '@src/domain/core/utils/analytics'

const LOGGER = getLogger('app')

const inter = Inter({
  weight: ['400', '500'],
  subsets: ['latin'],
  display: 'swap',
})
const clientConfig = getClientConfig()

const AppProviders = dynamic(
  () => import('@src/domain/core/providers/app-providers').then((it) => it.AppProviders),
  {
    ssr: false,
  },
)

export default function RootLayout({ children }: { children: ReactNode }) {
  const isFeatureEnabled = useIsFeatureEnabled()
  const currentLocale = useGlobalSettingsStore((state) => state.locale)
  const currentColorScheme = useGlobalSettingsStore((state) => state.colorScheme)

  useEffect(() => {
    LOGGER.debug(
      'Loading app (%s:%s:%s)',
      clientConfig.runtimeEnv,
      clientConfig.nodeEnv,
      clientConfig.buildId,
    )

    if (clientConfig.app.amplitudeApiKey) {
      analytics.init(clientConfig.app.amplitudeApiKey)
    }

    if (isFeatureEnabled('MAINNET_REDIRECT_TO_NEW_URL')) {
      if (window.location.hostname === 'app.prod.x10.exchange') {
        window.location.assign(MAINNET_URL)
      }
    }

    if (clientConfig.isProdRuntimeEnv) {
      printJobOpportunity()
    }
  }, [isFeatureEnabled])

  return (
    <LayoutHtml
      lang={currentLocale}
      theme={DEFAULT_THEME}
      colorScheme={currentColorScheme}
    >
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />

        <FavIcon title={APP_TITLE} />

        <title>{APP_TITLE}</title>
      </head>

      <body
        className={inter.className}
        style={{
          [CSS_STATIC_COLUMN_WIDTH_VAR]: '21.4375rem',
        }}
      >
        <AppProviders>
          <DocumentTitleUpdater />
          {children}
        </AppProviders>
        {clientConfig.app.googleAnalyticsId && (
          <GoogleAnalytics gaId={clientConfig.app.googleAnalyticsId} />
        )}
      </body>
    </LayoutHtml>
  )
}
